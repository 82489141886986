
import { defineComponent, toRefs, computed, reactive, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import editButton from '@/components/editButton/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import { AnnouncementClass, ReqAnnouncementClass } from '@/model/customerManagement/announcement'
import {
  AddFollowAnnouncement,
  CancelFollowAnnouncement,
  PageAnnouncement,
  RefreshAnnouncement,
} from '@/axios/api/announcement'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    yTable,
    FormItem,
    editButton,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    //获取路由
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj

    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqAnnouncementClass(),
      dataSource: [] as AnnouncementClass[],
      columns: [
        {
          title: '公告名称',
          dataIndex: 'announcementName',
          width: 400,
          key: 'announcementName',
          resizable: true,
        },
        {
          title: '公司名称',
          dataIndex: 'customerName',
          width: 130,
          key: 'customerName',
          resizable: true,
        },
        {
          title: '所属行业',
          dataIndex: 'industry',
          width: 180,
          key: 'industry',
          resizable: true,
        },
        {
          title: '所属内部行业',
          dataIndex: 'innerIndustry',
          width: 180,
          key: 'innerIndustry',
          resizable: true,
        },

        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 150,
          key: 'releaseTime',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'industryName',
          placeholder: '请输入行业名称',
          label: '行业名称',
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            { label: '关注', value: 1 },
            { label: '未关注', value: 0 },
          ],
        },
      ] as FormItemListType<AnnouncementClass>,
    })

    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    console.log(pageData.selectFrom)
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      PageAnnouncement(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })

    //搜索
    const search = () => {
      selectProduct()
    }

    //重置
    const reset = () => {
      pageData.selectFrom.data = new AnnouncementClass()
      selectProduct()
    }

    // 全选事件
    const checkedChange = (checked: boolean) => {
      pageData.selectedRowKeys = checked
        ? (pageData.dataSource.map((item) => item.id) as string[]) || []
        : []
    }

    const follow = (data: AnnouncementClass) => {
      if (data.isFollow === 1) {
        CancelFollowAnnouncement(data.id as string)
          .then(() => {
            message.success('取消关注成功')
            data.isFollow = 0
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        AddFollowAnnouncement(data.id as string)
          .then(() => {
            message.success('关注成功')
            data.isFollow = 1
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }

    // 跳转详情
    const goDetail = (record: AnnouncementClass) => {
      if (record.detail) {
        window.open(record.detail)
      }
    }

    // 刷新接口
    const refresh = () => {
      RefreshAnnouncement()
        .then(() => {
          message.success('正在刷新，请稍等。')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    return {
      refresh,
      goDetail,
      follow,
      ...toRefs(pageData),
      search,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      checkedChange,
      updateColumns,
    }
  },
})
