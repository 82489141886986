/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-11 16:00:58
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-18 17:12:24
 */
/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-11 09:38:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-11 09:43:44
 */
import { RegAnnouncementList, ReqAnnouncementClass } from '@/model/customerManagement/announcement'
import { $axios } from '../config/axios'
/**
 * @description:上市公司公告分页
 * @param {ReqFinancialStatementClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=6180
 */
export const PageAnnouncement = (data: ReqAnnouncementClass): Promise<RegAnnouncementList> => {
  return $axios.post('api/bcic/customer/announcement/page', data)
}

/**
 * @description:上市公司添加关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=6326
 */
export const AddFollowAnnouncement = (id: string): Promise<unknown> => {
  return $axios.post('api/bcic/customer/announcement/follow/add', {}, { params: { id } })
}

/**
 * @description:上市公司公告取消关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=6514
 */
export const CancelFollowAnnouncement = (id: string): Promise<unknown> => {
  return $axios.delete('api/bcic/customer/announcement/follow/cancel', { params: { id } })
}

/**
 * @description:刷新公告信息
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=6514
 */
export const RefreshAnnouncement = (): Promise<unknown> => {
  return $axios.post('api/bcic/customer/announcement', {})
}
